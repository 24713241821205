import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import {
  About,
  Blog,
  FalseCelling,
  FlatInteriorDesign,
  HomePage,
  KitchenMain,
  Layout,
  ModernBathroom,
  OfficeMain,
  Portfolio,
  ServiceHome,
} from "./Pages";

const App = () => {
  let location = useLocation();
  let path = location?.pathname;
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="office" element={<OfficeMain />} />
          <Route path="kitchen" element={<KitchenMain />} />
          <Route path="service-home" element={<ServiceHome />} />
          <Route path="false-celling" element={<FalseCelling />} />
          <Route path="flat-interior-design" element={<FlatInteriorDesign />} />
          <Route path="modern-bathroom" element={<ModernBathroom />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="about" element={<About />} />
          <Route path="blog" element={<Blog />} />
        </Route>
        <Route
          path="*"
          element={
            <h1
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: 200,
              }}
            >{`${path} This page is Not Found!`}</h1>
          }
        />
      </Routes>
    </>
  );
};

export default App;
