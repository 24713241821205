import React, { useEffect, useState } from "react";
import { Typewriter } from "react-simple-typewriter";
import {
  BedroomOne,
  BedroomTwo,
  BedroomThree,
  BedroomFour,
  BedroomFive,
  BedroomSix,
  DoorImg,
  HotelOne,
  HotelTwo,
  HotelThree,
  HotelFour,
  HotelFive,
  Salun,
} from "../Images";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaMapMarkerAlt, FaPhoneVolume } from "react-icons/fa";
import { MdMarkEmailRead } from "react-icons/md";
import { Asterisk, Contact, VisibleError } from "../Components/Index";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const HomePage = () => {
  const [homeData, setHomeData] = useState();

  const reduxData = useSelector((state) => state);

  const { content } = reduxData;

  useEffect(() => {
    if (content?.homePage && content?.homePage.length > 0) {
      setHomeData((prev) => content.homePage);
    }
  }, [content]);

  return (
    <>
      <div className="hero-bg text-center">
        <div className="position-relative">
          <h3>
            <Typewriter
              words={[
                "Premium",
                "Modern",
                "Best in class",
                "Elegant",
                "Boutique",
              ]}
              loop={false}
              cursor
              cursorStyle="|"
              typeSpeed={70}
              deleteSpeed={50}
              delaySpeed={1000}
            />
          </h3>
          <h1>Interior Designer & Decorators</h1>
          <a href="/" className="quote">
            Get Free Quote
          </a>
          <a href="/" className="text-center downArrow">
            <div className="arrow arrow-first"></div>
            <div className="arrow arrow-second"></div>
            <div className="arrow arrow-third"></div>
          </a>
        </div>
      </div>
      <div className="home-design">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center">
              <h3>Home Design</h3>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-sm-3 col-6">
              <a href="/">
                <img src={BedroomOne} className="small_img" alt="" />
              </a>
            </div>
            <div className="col-sm-3 col-6">
              <a href="/">
                <img src={BedroomTwo} className="small_img" alt="" />
              </a>
            </div>
            <div className="col-sm-3 col-6">
              <a href="/">
                <img src={BedroomThree} className="small_img" alt="" />
              </a>
            </div>
            <div className="col-sm-3 col-6">
              <a href="/">
                <img src={BedroomFour} className="small_img" alt="" />
              </a>
            </div>
            <div className="col-sm-6 col-12">
              <a href="/">
                <img src={BedroomFive} className="big_img" alt="" />
              </a>
            </div>
            <div className="col-sm-6 col-12">
              <a href="/">
                <img src={BedroomSix} className="big_img" alt="" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h5 className="text-center">
                <a href="/">
                  Explore Our Designs <FaArrowRightLong />
                </a>
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="quote-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              {/* <img src={Salun} alt="" /> */}
              {/* <img src={DoorImg} alt="" /> */}
              <h2>
                IF OPPORTUNITY DOESN'T KNOCK, <br />
                <b>BUILD</b> A Door
              </h2>
            </div>
          </div>
        </div>
      </div>
      {homeData &&
        homeData.map((item, i) => {
          const left = i == 0 && i % 2 == 0 ? 1 : 2;
          const right = i % 2 !== 0 ? 1 : 2;

          return (
            <>
              <div className="home-design hotel-design" key={i}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 text-center">
                      <h3>{item.name} Design</h3>
                    </div>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-sm-8 col-12" style={{ order: left }}>
                      <div className="slide-animation">
                        <img src={item.heroImg} className="big_img" alt="" />
                        <a href="/">
                          <div className="description-wrapper">
                            <p>Inspiration</p>
                            <h5>{item.name} Design</h5>
                          </div>
                        </a>
                      </div>
                      <div className="slide-animation">
                        <img src={item.images[2]} className="big_img" alt="" />
                        <a href="/">
                          <div className="description-wrapper">
                            <p>Inspiration</p>
                            <h5>{item.name} Design</h5>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4 col-6" style={{ order: right }}>
                      {item.images.map((each, x) => {
                        return (
                          <>
                            <div className="slide-animation" key={x}>
                              <img src={each} className="small_img" alt="" />
                              <a href="/">
                                <div className="description-wrapper">
                                  <p>Inspiration</p>
                                  <h5>{item.name} Design</h5>
                                </div>
                              </a>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <h5 className="text-center">
                        <Link to={item.url}>
                          Explore Our Designs <FaArrowRightLong />
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      <div className="map-section d-flex">
        <iframe
          title="location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7362.651592241156!2d88.40253014449654!3d22.67891265477162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89e97419e6345%3A0x496af2b39af33810!2sFatullahpur%2C%20Nimta%2C%20Kolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1723734872331!5m2!1sen!2sin"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div id="contact_sec">
        <Contact />
      </div>
    </>
  );
};

export default HomePage;
