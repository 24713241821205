import React from "react";

const Asterisk = () => {
  return (
    <>
      <span
        className="ml-1"
        style={{
          color: "red",
          fontSize: "20px",
          lineHeight: "0px",
        }}
      >
        *
      </span>
    </>
  );
};

export default Asterisk;
