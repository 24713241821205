import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaMapMarkerAlt,
  FaTwitter,
} from "react-icons/fa";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdMarkEmailRead } from "react-icons/md";

const Footer = () => {
  return (
    <>
      <div className="main-footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="widget contact-widget">
                <h3 className="widget-title">About Us</h3>
                <div className="about-widget">
                  <p className="text">
                    As an interior designer, we bring spaces to life with
                    creativity and functionality.We blend colors, textures, and
                    furniture to craft harmonious environments. By understanding
                    clients' needs, Imagine Interior creates personalized
                    designs that reflect their style and enhance their
                    lifestyle. Each project is a unique journey, transforming
                    dreams into reality.
                  </p>
                  <ul className="social-links">
                    <li>
                      <a href="/">
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="/">
                        <FaTwitter />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="widget contact-widget">
                <h3 className="widget-title">Quick Links</h3>
                <div className="footer-links">
                  <ul>
                    <li>
                      <a href="/">Services</a>
                    </li>
                    <li>
                      <a href="/">Portfolio</a>
                    </li>
                    <li>
                      <a href="/">About Us</a>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <a href="/">Blog</a>
                    </li>
                    <li>
                      <a href="/">Contact Us</a>
                    </li>
                    <li>
                      <a href="/">Terms & Condition</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="widget contact-widget">
                <h3 className="widget-title">Contact Us</h3>
                <ul className="contact-info">
                  <li>
                    <div className="icon">
                      <FaPhoneVolume />
                    </div>
                    <div className="text">
                      Helpline 24/7 <br />
                      <a href="tel:9836160115">+91 9836160115</a>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <MdMarkEmailRead />
                    </div>
                    <div className="text">
                      Email Us <br />
                      <a href="mailto:imagineinterior2012@gmail.com">
                        imagineinterior2012@gmail.com
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <FaMapMarkerAlt />
                    </div>
                    <div className="text">
                      92, Fatullapur, Nimta Kolkata, West Bengal 700049, India
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom text-center">
        <p className="mb-0">Copyright © 2021 All Rights Reserved</p>
      </div>
    </>
  );
};

export default Footer;
