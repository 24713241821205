import { createSlice } from "@reduxjs/toolkit";
import {
  butipalar,
  butipalar01,
  butipalar1,
  hotel,
  HotelFive,
  HotelFour,
  HotelOne,
  HotelRoom,
  HotelThree,
  Salun,
  Wating,
  WatingRoom,
} from "../../Images";

const ContentSlice = createSlice({
  name: "content",
  initialState: {
    homePage: [
      {
        name: "Saloon and Beauty Parlour",
        images: [Wating, WatingRoom, HotelRoom],
        heroImg: Salun,
        url: "/office",
      },
      {
        name: "Office ",
        images: [butipalar, butipalar1, butipalar01],
        heroImg: hotel,
        url: "/office",
      },
      {
        name: "Kitchen ",
        images: [HotelThree, HotelFour, HotelFive],
        heroImg: HotelOne,
        url: "/office",
      },
      {
        name: "Kitchen",
        images: [HotelThree, HotelFour, HotelFive],
        heroImg: HotelFour,
        url: "/kitchen",
      },
      {
        name: "Saloon",
        images: [HotelThree, HotelFour, HotelFive],
        heroImg: HotelOne,
        url: "/false-celling",
      },
      {
        name: "False Celling ",
        images: [HotelThree, HotelFour, HotelFive],
        heroImg: HotelFive,
        url: "/flat-interior-design",
      },
      {
        name: "Modern Bathroom",
        images: [HotelThree, HotelFour, HotelFive],
        heroImg: HotelFive,
        url: "/modern-bathroom",
      },
    ],
  },

  reducers: {},
});

export const {} = ContentSlice.actions;

export default ContentSlice.reducer;
