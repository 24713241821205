import { createSlice } from "@reduxjs/toolkit";

const AuthSlice = createSlice({
  name: "auth",
  initialState: {
    authority: "yes",
    apiData: [],
    token: null,
    userData: {},
  },

  reducers: {
    modifyAuthority: (state, action) => {
      state.authority = action.payload;
    },

    setToken: (state, action) => {
      state.token = action.payload;
    },
    clearToken: (state) => {
      state.token = null;
    },
    setUserDataAll: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const { modifyAuthority, setToken, clearToken, setUserDataAll } =
  AuthSlice.actions;

export default AuthSlice.reducer;
