import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import { ContentSlice } from "./reducers";

const rootReducer = combineReducers({
  auth: authReducer,
  content: ContentSlice,
});

export default rootReducer;
