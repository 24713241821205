import React from "react";
import kitchenBg from "../Images/kitchen-bg-2-.jpeg";

const Portfolio = () => {
  return (
    <div>
      <div
        className="page-header"
        style={{ backgroundImage: `url(${kitchenBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-header-box">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Portfolio</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
