import React from "react";

const VisibleError = ({ show, name = "value" }) => {
  return (
    <span
      style={{
        visibility: show ? "visible" : "hidden",
        fontSize: "13px",
        color: "red",
        paddingLeft: "10px",
        textAlign: "center",
      }}
    >
      {`Please enter ${name}`}
    </span>
  );
};

export default VisibleError;

export const ValidEmail = (emailId) => {
  const regEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let reg = regEx.test(String(emailId).toLowerCase());
  return reg;
};
