import React, { useState } from "react";
import kitchenBg from "../Images/kitchen-bg-2-.jpeg";
import { InnerImg } from "../Images";
import { FaCalendarDays, FaHandshakeSimple, FaUsers } from "react-icons/fa6";
import { GoProjectTemplate } from "react-icons/go";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const About = () => {
  const [counterUp, setCounterUp] = useState(false);
  return (
    <>
      <div
        className="page-header"
        style={{ backgroundImage: `url(${kitchenBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-header-box">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">About</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="services_inner">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-xl-5 mt-4 mt-xl-0">
              <div className="services-about-img">
                <img src={InnerImg} alt="node-js-development" />
              </div>
            </div>
            <div className="col-12 col-md-12 col-xl-7 align-self-center">
              <h2 className="about_title">
                About <span>Interior</span>
              </h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                fugiat beatae nobis, eaque, optio cupiditate, quasi amet error
                animi nemo vitae ipsum pariatur aliquam accusantium
                necessitatibus natus ea quidem iusto. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Neque fugiat beatae nobis, eaque,
                optio cupiditate, quasi amet error animi nemo vitae ipsum
                pariatur aliquam accusantium necessitatibus natus ea quidem
                iusto.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                fugiat beatae nobis, eaque, optio cupiditate, quasi amet error
                animi nemo vitae ipsum pariatur aliquam accusantium
                necessitatibus natus ea quidem iusto. Lorem ipsum dolor sit amet
                consectetur adipisicing elit.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque
                fugiat beatae nobis, eaque, optio cupiditate, quasi amet error
                animi nemo vitae ipsum pariatur aliquam accusantium
                necessitatibus natus ea quidem iusto. Lorem ipsum dolor sit amet
                consectetur adipisicing elit. Neque fugiat beatae nobis, eaque,
                optio cupiditate, quasi amet error animi nemo vitae ipsum
                pariatur aliquam accusantium necessitatibus natus ea quidem
                iusto.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="about_bg">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-xl-4">
              <h3>Hello! I'm Sakir Ali</h3>
              <p>
                As an interior designer, I transform spaces into havens that
                reflect clients' personalities.Every project is a journey where
                creativity meets functionality, balancing aesthetics with
                practicality.From color schemes to furniture selection, every
                detail is meticulously curated to evoke the desired ambiance.I
                strive to create spaces that not only look beautiful but also
                enhance the quality of life.It's about crafting environments
                where people feel inspired, comfortable, and truly at home.
              </p>
              <div style={{ color: "#913829" }}>@Founder</div>
            </div>
            <div className="col-md-12 col-xl-4">
              <img
                src="https://imagineinterior.in/images/dummy-men.jpg"
                alt=""
              />
            </div>
            <div className="col-md-12 col-xl-4">
              <ScrollTrigger
                onEnter={() => setCounterUp(true)}
                onExit={() => setCounterUp(false)}
              >
                <div className="counter_wrap text-center">
                  <h4>
                    {counterUp && (
                      <CountUp
                        start={0}
                        end={5}
                        duration={2}
                        delay={0}
                      ></CountUp>
                    )}
                  </h4>
                  <div className="counter_icon d-flex align-items-center justify-content-center gap-2">
                    <FaCalendarDays />
                    <p className="mb-0">Years of experience</p>
                  </div>
                </div>
                <div className="counter_wrap text-center">
                  <h4>
                    {counterUp && (
                      <CountUp
                        start={0}
                        end={250}
                        duration={2}
                        delay={0}
                      ></CountUp>
                    )}
                  </h4>
                  <div className="counter_icon d-flex align-items-center justify-content-center gap-2">
                    <GoProjectTemplate />
                    <p className="mb-0">Completed Projects</p>
                  </div>
                </div>
                <div className="counter_wrap text-center">
                  <h4>
                    {counterUp && (
                      <CountUp
                        start={0}
                        end={110}
                        duration={2}
                        delay={0}
                      ></CountUp>
                    )}
                  </h4>
                  <div className="counter_icon d-flex align-items-center justify-content-center gap-2">
                    <FaHandshakeSimple />
                    <p className="mb-0">Happy Clients</p>
                  </div>
                </div>
                <div className="counter_wrap text-center">
                  <h4>
                    {counterUp && (
                      <CountUp
                        start={0}
                        end={25}
                        duration={2}
                        delay={0}
                      ></CountUp>
                    )}
                  </h4>
                  <div className="counter_icon d-flex align-items-center justify-content-center gap-2">
                    <FaUsers />
                    <p className="mb-0">Designing experts</p>
                  </div>
                </div>
              </ScrollTrigger>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
